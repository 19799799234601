@import "/src/Resources/scss/variables/_variables.scss";

@mixin phone {
	@media only screen and (max-width: 767px) {
		@content;
	}
}

@mixin big-phone {
	@media only screen and (min-width: 768px) and (max-width: 880px) {
		@content;
	}
}

@mixin tablet {
	@media only screen and (min-width: 881px) and (max-width: 1024px) {
		@content;
	}
}

@mixin big-tablet {
	@media only screen and (min-width: 1025px) and (max-width: 1199px) {
		@content;
	}
}

@mixin laptop {
	@media only screen and (min-width: 1200px) and (max-width: 1500px) {
		@content;
	}
}

@mixin big-laptop {
	@media only screen and (min-width: 1501px) and (max-width: 1800px) {
		@content;
	}
}

@mixin desktop {
	@media only screen and (min-width: 1801px) {
		@content;
	}
}

.ellipsis-1 {
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
}

.ellipsis-2 {
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.ellipsis-3 {
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}

h1 {
	color: $secondary;
	font-weight: 900;
	margin-bottom: 0;
}

p {
	color: $secondary-50;
	margin-bottom: 0;
}

label {
	font-weight: 500;
	color: $secondary !important;
}

.card {
	margin-bottom: 0;
}

// Ajustes del menú de navegación
.main-menu.menu-fixed.menu-light {
	background-color: white;

	.scrollbar-container.main-menu-content {
		margin-top: 20px;
		overflow: visible !important;

		ul.navigation-main {
			background-color: transparent;
			overflow: visible !important;
			display: flex;
			flex-direction: column;
			gap: 5px;
		}
	}
}

// Ajustes al Navbar
.navbar-container {
	padding: 0.5rem !important;
}
